.navbarcontainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;
    height: 70px;
}

.logo_container{
    width: 20%;
    margin-left: 20px;
}

#logo_container_img{
    max-width: 100%;
    min-height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.navbar_menu{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.navbar_menu a{
    color:white;
    text-decoration: none;
}

.navbar_menu a:hover{
    cursor: pointer;
}

#Rbtn{
    width: 35px;
    height: 25px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    right: 32px;
    top: 25px;
    display: none;
}

#Rbtn:hover{
    cursor: pointer;
}

.Rbtnchild{
    background-color: white;
    width: 100%;
    height: 4px;
}

@media screen and (max-width:700px) {
    #Rbtn{
        display: flex;
    }
    
    .navbar_menu{
        display: none;
    }    
    
    .nav-buttonsstyle{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100px;
        height: 200px;
        background-color: #464d53;
        position: absolute;
        right: 0px;
        top: 64px;
        z-index: 100;
    }

    .nav-buttonsstyle  a{
        text-decoration: none;
        color: white;
        font-size: 16px;
    }

}

@media screen and (max-width:500px) {
    .navbarcontainer{
        height: 100px;
    }

    .logo_container{
        width: 35%;
        margin-left: 20px;
        height: 70%;
    }
    
    #logo_container_img{
        max-width: 100%;
        min-height: 100%;
        object-fit: contain;
        overflow: hidden;
    }
}
