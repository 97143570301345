.programs-container{
    height: 65%;
}

.programs-container-child1{
    min-height: 155px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.programs-container-child2{
    padding-left: 7%;
}

.programs-container-child2-button{

    background-color: black;
    border: none;
    color: white;
    height: 32px;
    width: 85px;
    border-radius: 9px;
    font-weight: 700;
    padding: 2px;
}

.programs-container-child2-button:hover{
    cursor: pointer;
}

.programs-container-child1-h1{
    font-size: 1.5rem;
    color: white;
    letter-spacing: 1px;
    font-weight: 300;
    font-family: Inter,sans-serif;
    word-spacing: 5px;
    padding-left: 7%;
    padding-right: 5%;
}

.programs-container-child1-p{
    padding-left: 7%;
    color: white;
    padding-right: 5%;
}

@media screen and (max-width:1180px){
    .programs-container-child1-h1{
        font-size: 1.2rem;
    }
}

@media screen and (max-width:930px){
    .programs-container-child1-h1{
        font-size: 17px;
        word-spacing: initial;
        letter-spacing: initial;
    }

    .programs-container-child1-p{
        word-spacing: initial;
        letter-spacing: initial;
    }
}

@media screen and (max-width:500px){
    .programs-container-child1-h1{
        font-size: 24px;
    }
}


