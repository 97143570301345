.herotext-container{
    width: 600px;
    height: 284px;
    margin-left: 5%;
    font-family: Inter,sans-serif;
    color:white;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.herotext{
    width: 100%;
    height: 35%;
    /*font-size: 540%;*/
    font-size: 5em;
    word-spacing: 20px;
    font-weight: 500;
}

#shapeyour_id{
    margin-top: 5%;
}

.herotext-font-border{
    color:	black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.herotext-normalfont{
    margin-left: 8%;
}

.herotext-description{
    height: 20%;
    font-size: 1.2em;
}

@media screen and (max-width:1100px){
    .herotext-container{
        font-size: 14px;
    }
}

@media screen and (max-width:1000px){
    .herotext-container{
        width: 550px;
    }
}

@media screen and (max-width:950px){
    .herotext-container{
        font-size: 13px;
        width: 500px;
    }

    .herotext-normalfont{
        margin-left: 30px;
    }
}

@media screen and (max-width:750px){
    .herotext-container{
        font-size: 12px;
        width: 410px;
    }
}

@media screen and (max-width:500px)
{
    .herotext-container{
        width: 100%;
        margin: initial;
        font-size: 8px;
        height: initial;
    }

    .herotext{
        width: 90%;
        height: 70px;
        margin: auto;
        word-spacing: initial;
        font-weight: 500;
    }

    #shapeyour_id{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #herotext-ibody{
        text-align: center;
    }

    .herotext-normalfont{
        margin-left: 10px;
    }

    .herotext-description{
        height: 20%;
        width: 70%;
        font-size: 12px;
        margin: auto;
        text-align: center;
    }
}

@media screen and (max-width:380px)
{
    .herotext-container{
        font-size: 7px;
    }
    .herotext{
        width: 95%;
        margin: auto;
        word-spacing: initial;
        height: 60px;
    }
    .herotext-description{
        width: 85%;
    }
}

@media screen and (max-width:320px)
{
    .herotext-container{
        font-size: 7px;
    }
    .herotext{
        width: 95%;
    }
}