.HomeMain{
    box-sizing: border-box;
    width: 100%;
    height: 560px;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
}

@media screen and (max-width:500px) {
    .HomeMain{
        height: initial;
        flex-direction: column;
    }
}