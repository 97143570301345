.Programs-container{
    height: 398px;
    width: 100%;
    margin: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

#heading{
    color: white;
    height: 20%;
    width: 100%;
    font-family: Inter,sans-serif;
    font-size: 3rem;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 6px;
    padding-top: 10px;
    font-weight: 500;
    font-style: italic;
}

#program-menu{
    height: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.Program{
    height: 100%;
    width: 20%;
    background-color: #808080;
}

.Program:hover{
    background-image: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
}

.program-icon{
    color: white;
    height: 10%;
    font-size: 200%;
    margin: 10%;
}

@media screen and (max-width:1100px) {
    #heading{
        height: 20%;
        width: 100%;
        font-family: Inter,sans-serif;
        font-size: 35px;
        text-align: center;
        letter-spacing: 1px;
        word-spacing: 2px;
        padding-top: 10px;
        font-weight: 500;
        font-style: italic;
    }
}

.blurpg{
    height:5%;
    width: 70%;
    background: rgba(253,120,43,0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(70px);
    z-index: 10;
}

@media screen and (max-width:850px){
    .Programs-container{
        height: initial;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #program-menu{
        height: 1400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Program{
        height: 300px;
        max-width: 300px;
        min-width: 220px;
        background-color: #808080;
    }

    .program-icon{
        height: initial;
        font-size: 40px;
        margin: 10%;
    }

    #heading{
        height: 20%;
        width: 100%;
        font-size: 26px;
        text-align: center;
        font-style: italic;
    }
}

@media screen and (max-width:500px){
    .Programs-container{
        height: initial;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #program-menu{
        height: 1400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Program{
        height: 300px;
        max-width: 300px;
        min-width: 220px;
        background-color: #808080;
    }

    .program-icon{
        height: initial;
        font-size: 40px;
        margin: 10%;
    }

    #heading{
        height: 20%;
        width: 100%;
        font-size: 26px;
        text-align: center;
        font-style: italic;
    }
}

