.leftHeaderMain{
    height: 100%;
    width: 75%;
    background-color: #3c3f45;
    margin: 0px;
    font-size: 1rem;
    position: relative;
}

.blurlh{
    height: 25%;
    width: 30%;
    background: rgba(253,120,43,0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(70px);
    z-index: 10;
}

#blurlhbottom{
    height: 11%;
    width: 90%;
    background: rgba(253,120,43,0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(70px);
    z-index: 10;
}

@media screen and (max-width:500px) {
    .leftHeaderMain{
        width: 100%;
        height: initial;
        
    }

    .blurlh{
        height: 20%;
        width: 90%;
    }
}