.Plans-container{
    height: 455px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: Inter,sans-serif;
    margin: 20vh auto;
    position: relative;
}

@media screen and (max-width:800px){
    .Plans-container{
        height: 1800px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-family: Inter,sans-serif;
        margin-bottom: 50px;
        margin-top: 20px;
    }
}

@media screen and (max-width:500px) {
    .Plans-container{
        height: 1800px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-family: Inter,sans-serif;
        margin-bottom: 50px;
        margin-top: 20px;
    }
}

@media screen and (max-width:300px){

}