.best-ad{
    height: 57px;
    width: 361px;
    color: #fff;
    text-transform:uppercase;
    font-size: 1.02em;
    padding-top: 12px;
    margin-top: 5%;
    margin-left: 5%;
    text-align: center;
    border-radius: 35px;
    background-color: #464d53;
    filter: contrast(200%);
    display: flex;
    flex-direction: row;
    word-spacing: 5px;
    position: relative;
}

@keyframes animatecapsule{
    0% {left: 0%;}
    10% {left: 14%;}
    20% {left: 27%;}
    30% {left: 39%;}
    40% {left: 55%;}
    50% {left: 71%;}
    60% {left: 55%;}
    70% {left: 39%;}
    80% {left: 25%;}
    90% {left: 11%;}
    100% {left: 0%;}
}

.bestad-capsule{
    margin: -6px 2%;
    width: 25%;
    height: 80%;
    position: absolute;
    border-radius: 26px;
    background-image: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
    z-index: -1;
    animation: animatecapsule 4s linear infinite;
}

.bestad-content{
    margin: 7px  auto;
    z-index: 1;
}

@media screen and (max-width:500px){
    .best-ad{
        width: 260px;
        margin: auto;
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: initial;
    }

    .bestad-capsule{
        margin: initial;
        margin-left: 2%;
        height: 80%;
    }

    .bestad-content{
        font-size: 11px;
        margin: initial;
        width: 100%;
    }
}