.joinus-container{
    height: 260px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
}

.blurjs{
    height: 20%;
    width: 70%;
    background: rgba(253,120,43,0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(70px);
    z-index: 10;
    margin-bottom: 500px;
}

.joinus-content{
    height: 55%;
    width: 550px;
    font-size: 2.9rem;
    font-weight: 600;
}

.joinus-email{
    height: 30%;
    width: 384px;
    background-color: #656565;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.txt-stroke{
    color: #3c3f45;
    margin-right: 15px;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 1px;
}

.txt-solid{
    color: white;
    margin-right: 15px;
}

.email-input{
    width: 55%;
    background: transparent;
    color:white;
    border: none;
}

.email-input::placeholder{
    color: white;
}

.email-input:focus{
    outline: none;
}


.email-submit{
    width: 30%;
    height: 60%;
    border: none;
    font-size: 1.2rem;
    background: #ffa739;
    color: white;
    font-weight: 600;
}

.email-submit:hover{
    cursor: pointer;
}

#ready{
    border-top: 5px solid #ffa739;
    border-radius: 5px;
}

@media screen and (max-width:1100px){
    .joinus-email{
        width: 280px;
    }

    .email-submit{
        font-size: 1rem;
    }
}

@media screen and (max-width:850px) {
    .joinus-container{
        height: 260px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .joinus-content{
        height: 55%;
        width: 500px;
        padding-left: 20px;
        font-size: 40px;
        font-weight: 600;
    }

    .joinus-email{
        width: 500px;
        margin: 5px auto;
    }

    .email-submit{
        width: 90px;
    }
}

@media screen and (max-width:500px) {
    .joinus-container{
        height: 260px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .joinus-content{
        height: 30%;
        width: 98%;
        padding-left: 20px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .joinus-email{
        width: 100%;
        margin: 5px auto;
    }

    .email-submit{
        width: 90px;
    }
}

@media screen and (max-width:300px){
    .joinus-content{
        padding-left: 0;
        font-size: 1.35rem;
    }
}