.rightHeaderMain
{
    height: 100%;
    width: 25%;
    background-color: #f48916;
    background-image: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
    font-family: Inter,sans-serif;
    position: relative;
}

.rightHeaderMain-button-container{
    height: 68px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rightHeaderMain-button{
    background-color: white;
    border: none;
    font-weight: 600;
    width: 96px;
    height: 55%;
    margin-right: 10%;
    font-size: large;
}

.heartcard-container{
    margin-top: 7%;
    height: 142px;
}

.heartcard{
    height: 100%;
    width: 35%;
    background-color: #464d53;
    border-radius: 5px;
    position: relative;
    left: 40%;
}

#heartimage{
    max-width: 30%;
    margin-top: 20px;
    margin-left: 20px;
}

.heading{
    height: 25%;
    color: gray;
    letter-spacing: 1px;
    word-spacing: 2px;
    text-align: center;
    padding-top: 15px;
    font-weight: 600;
    font-size: 90%;
    margin-top: 8%;
}

.heartrate{
    color: white;
    font-size: 1.5rem;
    height: 30%;
    word-spacing: 2px;
    text-align: center;
    padding-top: 5px;
    
}

#bgimg{
    position: absolute;
    min-height: 350px;
    top: 70px;
    left: -70%;
}

#heroimg{
    max-height: 450px;
    position: absolute;
    left: -50%;
    top: 25%;
    z-index: 50;
}

html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

@media screen and (max-width:1100px)
{
    html,body{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    .heartcard{
        border-radius: 5px;
        left: 55%;
    }

    .heartrate{
        font-size: 1rem;
        word-spacing: normal;
    }

    .heading{
        word-spacing: normal;
        color: white;
        font-size: 0.82rem;
    }

    #bgimg{
        min-height: 300px;
    }
    
    #heroimg{
        max-height: 400px;
    }
}

@media screen and (max-width:1000px) {
    .heartcard-container{
        margin-top: initial;
        height: 120px;
        display: flex;
        justify-content: flex-end;
    }
    
    .heartcard{
        height: 100%;
        width: 80px;
        background-color: #464d53;
        border-radius: 5px;
        position: initial;
        margin-right: 16px;
    }

    #heartimage{
        margin-top: 10px;
    }
    
    .heading{
        padding-top: initial;
        font-size: 16px;
    }
    
    .heartrate{
        font-size: 16px;
        padding-top: 20px;
        
    }
}

@media screen and (max-width:900px){
    #bgimg{
        min-height: 290px;
        display: none;
    }
    
    #heroimg{
        max-height: 380px;
    }
}

@media screen and (max-width:750px){
    #heroimg{
        max-height: 350px;
        top: 30%;
    }
    
    .heartcard{
        width: 45%;
    }
    
}

@media screen and (max-width:500px) {

    .rightHeaderMain
    {
        height: initial;
        width: 100%;
        background: #3c3f45;
        position: relative;
    }

    #heroimg{
        position: initial;
        position: relative;
        left: 30%;
        min-height: 380px;
        top: -20px;
    }

    #bgimg{
        display: initial;
        position: initial;
        position: absolute;
        left: 10%;
        top: 100px;
        min-height: 380px;
    }

    .heartcard{
        position: absolute;
        left: 32px;
        top: 16px;
        width: 110px;
        height: 140px;
        
    }

    .heartrate{
        font-size: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: initial;
    }

    
}



