.App{
    box-sizing: border-box;
    width: 100%;
    background-color: #3c3f45;
    font-family: Inter,sans-serif;
    height: 100%;
}

@media screen and (max-width:500px) {
    html,body{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }
}
