.reason-container{
    min-height: 460px;
    width: 100%;
    display: flex;
    margin-top: 100px;
    flex-direction: row;
    justify-content: space-around;
    font-family: Inter,sans-serif;
}

.reason-grid{
    height: 97%;
    width:45%;
    display: grid;
    grid-template-columns: 180px 180px 180px;
    grid-template-rows: 210px 210px;
    justify-content: space-evenly;
    align-content: space-evenly;
}

.reason-description{
    height: 100%;
    width: 45%;
}

.gridchild-1{
    grid-row: 1/3;
    overflow: hidden;
}

.gridchild-2{
    grid-column: 2/4;
    overflow: hidden;
}

.gridchild-3{
    overflow: hidden;
}

.gridchild-4{
    overflow: hidden;
}

#grid-img1{
    max-width: 178px;
}

#grid-img2{
    max-width: 370px;
}

#grid-img3{
    max-height: 210px;
}

#grid-img4{
    max-height: 208px;
}

/*    Description       */

.reason-description-h3{
    color: #f48915;
    letter-spacing: 1px;
    margin-bottom: 3%;
}

.reason-description-h1-ts{
    font-size: 350%;
    font-weight: 600;
    color: black;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 1px;
    margin-right: 3%;
}

.reason-description-h1{
    font-size: 350%;
    color: white;
}

.reason-description-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10%;
}

.reason-description-div-img{
    max-height: 30px;
    margin-right: 2%;
}

.reason-description-div-p{
    color: white;
    word-spacing: 4px;
    letter-spacing: 1px;
}

/*          Partners              */

.partners{
    margin-top: 5%;
    height: 27%;
}

.partners-h2{
    color: white;
    word-spacing: 7px;
    height: 30%;
    font-size: 150%;
    font-weight: 600;
}

.partnersimg-container{
    height: 70%;
}

.partner-image{
    min-width: 60px;
    min-height: 60px;
    margin-right: 2%;
    margin-top: 2%;
}

@media screen and (max-width:1100px) {
    .reason-container{
        justify-content: right;
    }
    .reason-description{
        width: 30%;
        margin-left: 10%;
        margin-right: 5%;
    }

   /* .partners{
        display: none;
        position: absolute;
    }*/

    .reason-description{
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width:900px) {
    .reason-container{
        width: 100%;
        height: 1200px;
        flex-direction: column;
        margin-top: 100px;
    }

    .reason-grid{
        height: 500px;
        max-width:450px;
        display: grid;
        grid-template-columns: 170px 170px 170px;
        grid-template-rows: 220px 220px;
        column-gap: 5px;
        row-gap: 5px;
        justify-content: space-evenly;
        align-content: space-evenly;
        margin: auto;
    }

    .reason-description{
        min-height: 600px;
        width: 70%;
        margin: 50px auto;
    }

    .reason-description-h3{
        text-align: center;
        font-size: 40px;
    }

    .partners-h2{
        text-align: center;
    }

    .partnersimg-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 80px;
    }
}

@media screen and (max-width:600px){
    .reason-description{
        width: 90%;
    }
}
@media screen and (max-width:500px) {
    .reason-container{
        width: 100%;
        height: 1200px;
        flex-direction: column;
        margin-top: 50px;
    }

    .reason-grid{
        height: 500px;
        max-width:450px;
        display: grid;
        grid-template-columns: 150px 150px;
        grid-template-rows: 210px 210px;
        column-gap: 5px;
        row-gap: 5px;
        justify-content: space-evenly;
        align-content: space-evenly;
        margin: auto;
    }
    .gridchild-1{
        grid-row: 1/3;
        overflow: hidden;
    }
    
    .gridchild-2{
        grid-column: 2/4;
        overflow: hidden;
        display: none;
    }
    
    .gridchild-3{
        overflow: hidden;
    }
    
    .gridchild-4{
        overflow: hidden;
    }

    .reason-description{
        min-height: 600px;
        width: 90%;
        margin: 50px auto;
    }

    .reason-description-h3{
        text-align: center;
        font-size: 32px;
    }

    .partners-h2{
        text-align: center;
    }

    .partnersimg-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 80px;
    }

    .partner-image{
        width: 90px;
        height: 100%;
    }
}