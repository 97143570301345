* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    color: black;
    width: 98.8vw;
    overflow-x: hidden;
    font-size: 16px;
    background-color: cyan;
}

@media screen and (max-width:500px) {
    html,body{
        width: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }
}
