.figures-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    width: 70%;
    min-height: 85px;
    margin-left: 5%;
}

@media screen and (max-width:750px) {
    .figures-container{
        width: 400px;
    }
}

@media screen and (max-width:500px) {
    .figures-container{
        margin: 20px auto;
        max-width: 80%;
    }
}

@media screen and (max-width:380px)
{
    .figures-container{
        min-width: 95%;
    }
}