.plancontainer{
    width: 25%;
    height: 100%;
    background-image: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 10px;
}

.plantitle{
    color: white;
    margin-top: 10%;
    margin-left: 10%;
}

.planamount{
    color: white;
    font-weight: 500;
    height: 20%;
    font-size: 300%;
    padding-top: 10px;
    margin-left: 10%;
}


.plandesc{
    width: 90%;
    height: 11%;
    display: flex;
    flex-direction: row;
    color: white;
    margin: 2% auto;
    margin-left: 10%;
}
.tickicon{
    max-width: 20px;
    max-height: 20px;
}
.plandesc-p{
    font-size: 125%;
    margin-left: 5%;
}

.benifitContent{
    height: 10%;
    width: 90%;
    color: white;
    font-size: larger;
    padding-top: 22px;
    margin-left: 10%;
}

.planSubmit{
    width: 80%;
    height: 10%;
    font-size: 1rem;
    border: none;
    margin:10%; 
    font-weight: 600;
}

.planSubmit:hover{
    cursor: pointer;
}

.benifitContent{
    word-spacing: 2px;
}

@media screen and (max-width:800px) {
    .plancontainer{
        width: 330px;
        height: 500px;
        background-image: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 10px;
        margin-top: -50px;
    }
}

@media screen and (max-width:500px) {
    .plancontainer{
        width: 330px;
        height: 500px;
        background-image: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 10px;
        margin-top: 20px;
    }
}

@media screen and (max-width:300px){
    .plandesc{
        font-size: 0.9rem;
    }
}