.footer-container{
    border-top: 2px solid white;
    height: 315px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.footer-icon-parent{
    height: 20%;
    width: 300px;
    text-align: center;
    z-index: 12;
}

.footericon{
    max-width: 40px;
    max-height: 40px;
    margin: auto 8%;
}

.footer-logo{
    height: 15%;
    width: 30%;
    text-align: center;
    margin-top: 1.5%;
    z-index: 12;
}

.footer-logo-child{
    max-width: 60%;
    max-height: 100%;
}

.blur{
    height: 20%;
    width: 70%;
    background: rgba(253,120,43,0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(70px);
    z-index: 10;
}

@media screen and (max-width:500px) {

    .footer-icon-parent{
        width: 70%;
    }

    .footer-logo-child{
        max-width: 100%;
    }

    .blur{
        height: 20%;
        width: 70%;
        background: rgba(253,120,43,0.69);
        position: absolute;
        border-radius: 50%;
        filter: blur(70px);
        z-index: 10;
    }
}

@media screen and (max-width:300px)
{
    .footer-icon-parent{
        width: 90%;
    }

    .footer-logo{
        height: 25%;
        width: 50%;
    }    
}