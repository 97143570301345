.Figure-container{
    width: 25%;
    height: 100%;
    font-family: Inter,sans-serif;
}

.Figure-child1{
    height: 70%;
    color: white;
    font-size: 250%;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 5px;
    background: -webkit-linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Figure-child2{
    width: 100%;
    height: 30%;
    color: #eeee;
    font-size: 0.9rem;
    word-spacing: 3px;
}

@media screen and (max-width:500px) {
    .Figure-container{
        max-width: 25%;
    }
    .Figure-child1{
        height: 70%;
        width: initial;
        color: white;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: initial;
    }
    
    .Figure-child2{
        width: 100%;
        height: 30%;
        color: #eeee;
        font-size: 11px;
        word-spacing: 3px;
        text-align: center;
    }
    
}